// Libraries
import React, { useState } from "react";
import {
  Grid,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Paper,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { Autocomplete } from "@material-ui/lab";
import Skeleton from "@material-ui/lab/Skeleton";
import SearchIcon from "@material-ui/icons/Search";

// Custom components
import NavigationTabs from "../components/NavigationTabs";
import AlertDialog from "../components/AlertDialog";
import SnackbarMessage from "../components/SnackbarMessage";
import OrderDetailsModal from "../components/Orders/OrderDetailsModal";

// API
import {
  getOrderInvoicesForApproval,
  searchOrdersForApprovalByid,
  updateOrderInvoiceStatus,
} from "../api/OrderInvoices";
import { getUserApprovalGroups } from "../api/Users";

// Styles
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: "65px",
    maxWidth: "xl",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
  },
  mainContainerClient: {
    marginTop: "65px",
    maxWidth: "xl",
  },
  margin: {
    margin: theme.spacing(1),
  },
  date_col: {
    [theme.breakpoints.up("lg")]: {
      minWidth: "170px",
    },
  },
  approval_col: {
    [theme.breakpoints.up("lg")]: {
      minWidth: "230px",
    },
  },
  paper: {
    padding: "20px",
  },
}));

// Theme for buttons
const theme = createMuiTheme({
  palette: {
    primary: {
      main: green[500],
      contrastText: "#FFF",
    },
    secondary: {
      main: red[500],
      contrastText: "#FFF",
    },
  },
});

// Main component
const ClientApprovals = () => {
  const classes = useStyles();
  // Initialize user_role
  let user_role = localStorage.getItem("role");
  let user_id = localStorage.getItem("user");
  const [orderList, setOrderList] = React.useState([]);
  const [orderSearch, setOrderSearch] = React.useState([]);
  const [loading_search, setLoadingSearch] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [updating, setUpdating] = React.useState(false);
  const [update_to, setUpdateTo] = React.useState({ id: null, status: null });
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(false);
  let { client_id } = useParams();

  // Execute this at first load & when client changes
  useEffect(async () => {
    let approval_groups = [];
    // For client order approvers
    if (user_role === "clientorderapprover") {
      // Retrieve their approval groups
      await getUserApprovalGroups(user_id).then((result) => {
        if (result && result.data && result.data.user) {
          approval_groups = result.data.user.approval_groups.map(
            (group) => group.id
          );
        }
      });
      // Show nothing if they has no approval groups
      if (approval_groups.length === 0) {
        // Set loading to false
        setLoading(false);
        return;
      }
    }
    // Retrieve orders considering approval groups (only for client order approvers)
    await getOrderInvoicesForApproval(client_id, approval_groups)
      .then((result) => {
        if (result?.data?.orderInvoices) {
          setOrderList(result.data.orderInvoices);
          setOrderSearch(result.data.orderInvoices);
        }
      })
      .finally(() => setLoading(false)); // Set loading to false
  }, [client_id]);

  // Manage errors
  const [error, setError] = React.useState({
    open: false,
    message: "",
    severity: "info",
  });

  // Close snackbar
  const handleCloseSnackbar = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  /**
   * Change order invoice status
   * @param {int} id
   * @param {string} status
   */
  const updateOrderStatus = (id, status) => {
    setUpdateTo({ id: id, status: status });
    setUpdating(true);
    // Update order invoice status
    updateOrderInvoiceStatus(id, status).then(() => {
      // Refresh data
      setLoading(true);
      getOrderInvoicesForApproval(client_id)
        .then((result) => {
          if (result?.data?.orderInvoices) {
            setOrderList(result.data.orderInvoices);
            setOrderSearch(result.data.orderInvoices);
            setError({
              open: true,
              message:
                "Order successfully " +
                (status.toLowerCase() == "denied" ? "denied" : "approved"),
              severity: "success",
            });
          } else {
            setError({
              open: true,
              message: "Error updating order status",
              severity: "error",
            });
          }
        })
        .finally(() => {
          setLoading(false);
          setUpdating(false);
        });
    });
  };

  const triggerDetailsModal = (id) => {
    setOpenDetailsModal(true);
    setSelectedOrderId(id);
  };

  const closeDetailsModal = () => {
    setOpenDetailsModal(false);
    setSelectedOrderId(false);
  };

  // Render view
  return (
    <Container maxWidth="xl" className={classes.mainContainer}>
      {client_id == 0 ? (
        <Grid
          container
          style={{ padding: "0 20px 20px 20px", marginTop: "20px" }}
        >
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              This user doesn&apos;t have any client, please contact an
              administrator.
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container style={{ padding: "20px" }}>
          <Grid item xs={12} md={10} style={{ paddingBottom: "20px" }}>
            <NavigationTabs value={"approval"} client_id={Number(client_id)} />
          </Grid>
          {/* Skeleton */}
          {loading ? (
            <Grid container justify="flex-end">
              <Grid item xs={12} sm={6} lg={4}>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  height={48}
                  width={422}
                />
              </Grid>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Skeleton animation="wave" variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" variant="rect" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" variant="rect" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[1, 2, 3, 4, 5, 6].map((order, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton animation="wave" variant="rect" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" variant="rect" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" variant="rect" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" variant="rect" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" variant="rect" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" variant="rect" />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : orderList.length == 0 ? (
            <Grid item xs={12}>
              <Paper elevation={3} className={classes.paper}>
                <Typography align="center" variant="h4">
                  No orders for approval
                </Typography>
              </Paper>
            </Grid>
          ) : (
            <Grid container justify="flex-end">
              <Grid item xs={12} sm={6} lg={4}>
                <Autocomplete
                  autoComplete
                  autoHighlight
                  clearOnEscape
                  freeSolo
                  id="search"
                  disableClearable
                  // clearOnBlur
                  options={[]}
                  getOptionLabel={(option) => option.id}
                  //options={products.map((option) => option.Name)}
                  // onChange={(event, value) => {
                  //   history.push(`/app/product/${value.id}`);
                  // }}
                  // loading={loading_search}
                  onInputChange={(event, value) => {
                    if (value.length > 0) {
                      setLoadingSearch(true);
                      searchOrdersForApprovalByid(client_id, value)
                        .then((result) => {
                          if (result?.data?.orderInvoices)
                            setOrderSearch(result.data.orderInvoices);
                        })
                        .finally(() => setLoadingSearch(false));
                    } else {
                      setOrderSearch(orderList);
                    }
                  }}
                  // onClose={() => setOrderSearch(orderList)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search an order number"
                      // placeholder="Search an order number"
                      margin="none"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",

                        endAdornment: (
                          <InputAdornment position="end">
                            {loading_search ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              <SearchIcon />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  {/* Headers */}
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography>
                          <Box fontWeight="fontWeightBold">Order Number</Box>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          <Box fontWeight="fontWeightBold">Date Submitted</Box>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          <Box fontWeight="fontWeightBold">Total</Box>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          <Box fontWeight="fontWeightBold">Ordered by</Box>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          <Box fontWeight="fontWeightBold">Items</Box>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          <Box fontWeight="fontWeightBold">Approval</Box>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {/* Product list & total */}
                  <TableBody>
                    {/* Order list */}
                    {orderSearch.map((order, index) => (
                      <TableRow key={index}>
                        {/* Order Number */}
                        <TableCell>
                          <Link
                            onClick={() => triggerDetailsModal(order.id)}
                            style={{ cursor: "pointer" }}
                          >
                            {order.id}
                          </Link>
                        </TableCell>
                        {/* Date Submitted */}
                        <TableCell className={classes.date_col}>
                          <Typography>
                            {new Date(order.created_at).toLocaleString()}
                          </Typography>
                        </TableCell>
                        {/* Total */}
                        <TableCell>
                          <Typography>${order.Total}</Typography>
                        </TableCell>
                        {/* Ordered by */}
                        <TableCell>
                          <Typography>
                            {`${order?.user?.Details?.FirstName} ${order?.user?.Details?.LastName}`.trim()}
                          </Typography>
                        </TableCell>
                        {/* Items */}
                        <TableCell>
                          <Typography>
                            {order.line_items.map((item, index, array) => {
                              const itemProduct = item.product
                                ? item.product
                                : null;
                              const productName = itemProduct
                                ? itemProduct.Name
                                  ? itemProduct.Name
                                  : ""
                                : "";
                              return (
                                productName +
                                (index == array.length - 1 ? "" : ", ")
                              );
                            })}
                          </Typography>
                        </TableCell>
                        {/* Approval */}
                        <TableCell
                          padding="none"
                          className={classes.approval_col}
                        >
                          <ThemeProvider theme={theme}>
                            <AlertDialog
                              openButtonProps={{ disabled: updating }}
                              openButtonText="APPROVE"
                              openButtonColor="primary"
                              openButtonStartIcon={
                                update_to &&
                                update_to.id === order.id &&
                                update_to.status === "Created" ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : (
                                  <CheckIcon />
                                )
                              }
                              openButtonSize="small"
                              openButtonClassName={classes.margin}
                              dialogTitle="Please confirm"
                              dialogContentText={
                                "Are you sure you want to approve order # " +
                                order.id +
                                "?"
                              }
                              closeButtonText="Cancel"
                              confirmButtonColor="primary"
                              confirmButtonText="APPROVE"
                              confirmButtonClick={() =>
                                updateOrderStatus(order.id, "Created")
                              }
                            />
                            <AlertDialog
                              openButtonProps={{ disabled: updating }}
                              openButtonText="DENY"
                              openButtonStartIcon={
                                update_to &&
                                update_to.id === order.id &&
                                update_to.status === "Denied" ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : (
                                  <ClearIcon />
                                )
                              }
                              openButtonSize="small"
                              openButtonClassName={classes.margin}
                              dialogTitle="Please confirm"
                              dialogContentText={
                                "Are you sure you want to deny order # " +
                                order.id +
                                "?"
                              }
                              closeButtonText="Cancel"
                              confirmButtonText="DENY"
                              confirmButtonClick={() =>
                                updateOrderStatus(order.id, "Denied")
                              }
                            />
                          </ThemeProvider>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      )}
      {/* Show success or error */}
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleCloseSnackbar}
      />
      <OrderDetailsModal
        open={openDetailsModal}
        orderId={selectedOrderId}
        handleClose={closeDetailsModal}
      />
    </Container>
  );
};

export default ClientApprovals;
