// Import Libraries
import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";

// API
import { usersListReports } from "../api/Reports";

// Import Components
import SnackbarMessage from "../components/SnackbarMessage";

//Styles needed for responsiveness
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
  },
  ListItem: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const Reports = React.forwardRef((props) => {
  const [error, setError] = useState({
    message: "",
    severity: "info",
    open: false,
  });
  const customStyle = ({ ...column }) => {
    return (
      <div
        className={classes.ListItem}
        style={{
          maxWidth: "100px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          fontWeight: "bold",
        }}
      >
        {column.label}
      </div>
    );
  };
  const customStyleText = (value = "-") => {
    return (
      <div
        style={{
          maxWidth: "110px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {value}
      </div>
    );
  };

  let initial_columns = [
    {
      name: "Employee_ID",
      label: "Employee ID",
      options: {
        filter: false,
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleText(value),
      },
    },
    {
      name: "Billing_Department",
      label: "Billing department",
      options: {
        filter: false,
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleText(value),
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleText(value),
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        filter: false,
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleText(value),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
      },
    },
  ];
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [clientFilter, setClientFilter] = useState(0);
  const [columns, setCol] = useState(initial_columns);
  const [data, setData] = useState([]);
  const options = {
    filterType: "checkbox",
    selectableRows: false,
    textLabels: {
      body: {
        noMatch: "Sorry, no matching records found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `${column.label}`,
      },
    },
    downloadOptions: {
      filename: "User Points",
      separator: ",",
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    responsive: "standard",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50, 100],
  };

  useEffect(() => {
    if (Array.isArray(props.clients) && props.clients.length > 0) {
      setClientFilter(props.clients[0].id);
    }
  }, [props.clients]);

  useEffect(() => {
    // Get user division points by client
    getUserDivisionPointsbyClient(clientFilter);
  }, [clientFilter]);

  /**
   * Close snackbar
   */
  const handleClose = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  /**
   * Get user division points by client
   * @param {*} clientId
   */
  const getUserDivisionPointsbyClient = (clientId) => {
    setLoading(true);
    usersListReports(clientId)
      .then((result) => {
        // Get the data
        const userData = result?.data?.getUsersDivisionsPoints || [];
        if (userData && Array.isArray(userData)) {
          // Check if there is data
          if (userData.length > 0) {
            // Get the division categories
            const divisionsCategories = JSON.parse(
              userData[0].Division_Categories
            );
            const divisions = divisionsCategories.map((division) => {
              const newColumn = {
                name: division.id.toLocaleString(),
                label: division?.Name || "N/A",
                options: {
                  customHeadLabelRender: customStyle,
                  customBodyRender: (value) => customStyleText(value),
                },
              };
              return newColumn;
            });
            // Add the new columns to the initial columns
            let newColumns = initial_columns.concat(divisions);
            setCol(newColumns);
            // Map the data to create the rows
            let dataClient = userData.map((dat) => {
              let rowData = {
                Employee_ID: dat?.Employee_ID || "-",
                Billing_Department: dat?.Billing_Department || "-",
                name: dat?.Name || "-",
                username: dat?.Username || "-",
                status: dat?.Status || "-",
              };
              // Convert the division categories to an array
              const divisionCategories =
                JSON.parse(dat.Division_Categories) || [];
              for (const division of divisions) {
                const foundDivision = divisionCategories.find(
                  (element) => element.id == division.name
                );
                rowData[division.name] =
                  foundDivision?.Balance && foundDivision.Balance != "N/A"
                    ? Number(foundDivision.Balance)
                    : "N/A";
              }
              return rowData;
            });
            setData(dataClient);
          }
        } else {
          // Get the error message
          let error_message = result.errors
            ? result.errors[0].message
            : "An error occurred";
          // Display error
          setError({
            open: true,
            message: `${error_message}. Please try again later.`,
            severity: "error",
          });
        }
      })
      .catch((error) => {
        console.log("Error:", error);
        setError({
          open: true,
          message: `An error occurred while loading the data. Please try again later.`,
          severity: "error",
        });
      })
      .finally(() => setLoading(false));
  };

  //view
  return (
    <Container
      style={{ marginTop: "65px" }}
      className={classes.mainContainer}
      maxWidth="xl"
    >
      <Grid container style={{ padding: "20px" }}>
        <Grid item xs={12} md={8} lg={4} style={{ paddingBottom: "20px" }}>
          {/* Search  && skeleton */}
          {loading ? (
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Skeleton height={40} />
              </Grid>
            </Grid>
          ) : (
            <FormControl id="ControlType" fullWidth>
              <InputLabel id="ControlType">Filter by client</InputLabel>
              <Select
                labelId="ControlType"
                id="ControlType"
                name="ControlType"
                value={clientFilter}
                onChange={(e) => {
                  setClientFilter(e.target.value);
                }}
              >
                {props.clients.map((client, index) => [
                  <MenuItem key={index} value={client.id}>
                    {client.name}
                  </MenuItem>,
                ])}
              </Select>
            </FormControl>
          )}
        </Grid>
        {/*Skeleton*/}
        {loading ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={9}>
                  <Skeleton
                    variant="rectangular"
                    height={30}
                    style={{ margin: "3px" }}
                    width={120}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ paddingRight: "25px", marginBottom: "20px" }}
                >
                  <Skeleton
                    variant="rectangular"
                    height={30}
                    style={{ margin: "3px", float: "right" }}
                    width={40}
                  />
                  <Skeleton
                    variant="rectangular"
                    height={30}
                    style={{ margin: "3px", float: "right" }}
                    width={40}
                  />
                  <Skeleton
                    variant="rectangular"
                    height={30}
                    style={{ margin: "3px", float: "right" }}
                    width={40}
                  />
                  <Skeleton
                    variant="rectangular"
                    height={30}
                    style={{ margin: "3px", float: "right" }}
                    width={40}
                  />
                  <Skeleton
                    variant="rectangular"
                    height={30}
                    style={{ margin: "3px", float: "right" }}
                    width={40}
                  />
                </Grid>
              </Grid>
              <Grid container>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((row, i) => {
                  return (
                    <Grid key={i} container>
                      <Grid item xs={1}>
                        <Skeleton
                          variant="rectangular"
                          height={30}
                          style={{ margin: "3px", marginBottom: "15px" }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Skeleton
                          variant="rectangular"
                          height={30}
                          style={{ margin: "3px" }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Skeleton
                          variant="rectangular"
                          height={30}
                          style={{ margin: "3px" }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Skeleton
                          variant="rectangular"
                          height={30}
                          style={{ margin: "3px" }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Skeleton
                          variant="rectangular"
                          height={30}
                          style={{ margin: "3px" }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} className={classes.ListItem}>
            <MUIDataTable
              title={"User Points"}
              data={data}
              columns={columns}
              options={options}
            />
          </Grid>
        )}
      </Grid>
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleClose}
      />
    </Container>
  );
});

Reports.displayName = "Reports";

Reports.propTypes = {
  clients: PropTypes.array,
};

export default Reports;
