import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as yup from "yup";
//Snackbar
import SnackbarMessage from "../SnackbarMessage";
//API
import { updateUserById } from "../../api/Users";
//Styles needed for buttons responsiveness
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  ButtonsContainers: {
    marginTop: "10px",
  },
  Button: {
    [theme.breakpoints.up("xs")]: {
      float: "right",
    },
  },
}));

const initialPassValues = {
  password: "",
  confirm_pass: "",
};

const validation_schema_required = yup.object({
  password: yup
    .string("Password")
    .min(8, "Password must contain at least 8 characteres")
    .required("Password is required"),
  confirm_pass: yup
    .string("Confirm Password")
    .min(8, "Password must contain at least 8 characteres")
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const validation_schema_optional = yup.object({
  password: yup
    .string("Password")
    .min(8, "Password must contain at least 8 characteres"),
  confirm_pass: yup
    .string("Confirm Password")
    .min(8, "Password must contain at least 8 characteres")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default function PasswordDialog(props) {
  const classes = useStyles();
  const { open, handleClose, user, userPrefix } = props;
  const [saving, setSaving] = useState(false);
  // Manage errors
  const [error, setError] = useState({
    message: "",
    severity: "error",
    open: false,
  });

  // Close snackbar
  const handleCloseSnackbar = () => {
    setError({
      open: false,
      message: "",
      severity: "error",
    });
  };

  const formik = useFormik({
    initialValues: initialPassValues,
    validationSchema:
      (props.currentRole == 7 && props.newRole == 7) || (props.currentRole != 7 && props.newRole == 7)
        ? validation_schema_optional
        : validation_schema_required,
    onSubmit: (original_values) => {
      let values = {...original_values}
      setSaving(true);
      if (((props.currentRole == 7 && props.newRole == 7) || (props.currentRole != 7 && props.newRole == 7)) && values.password.length === 0) {
        values.password = "uHFLoLmlcqg1&y!KhbnQW9LIoI*a9w3O";
      }
      if (user) {
        values.id = user.id;
        values.first_name = user.Details
          ? user.Details.FirstName
            ? user.Details.FirstName
            : ""
          : "";
        values.last_name = user.Details
          ? user.Details.LastName
            ? user.Details.LastName
            : ""
          : "";
        values.billing_department = user.Details
          ? user.Details.BillingDepartment
            ? user.Details.BillingDepartment
            : ""
          : "";
        values.site = user.Details
          ? user.Details.Site
            ? user.Details.Site
            : ""
          : "";
        values.username = user.username;
        values.email = user.email;
        values.test = user.test;
        values.role = props.newRole;
        values.Employee_ID = user.Employee_ID;
        if (values.role == 7) {
          if (!values.username.includes(userPrefix))
            values.usernameWithPrefix = userPrefix + values.username;
        }
        // Update user password
        updateUserById(values)
          .then((results) => {
            if (!results.data) {
              setSaving(false);
              setError({
                open: true,
                message: `An error occurred, please try again`,
                severity: "error",
              });
            } else {
              if (results.errors) {
                setSaving(false);
                setError({
                  open: true,
                  message: `An error occurred, please try again`,
                  severity: "error",
                });
              } else {
                setSaving(false);
                if (
                  props.currentRole == 7 &&
                  props.newRole != props.currentRole
                ) {
                  props.getUser();
                }

                Cancel();
                setError({
                  open: true,
                  message: `Password Updated Successfully`,
                  severity: "success",
                });
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  });

  const Cancel = () => {
    resetPassForm();
    handleClose();
  };

  const resetPassForm = () => {
    formik.resetForm({
      password: "",
      confirm_pass: "",
    });
  };

  return (
    <div>
      <Dialog
        id="change_password_dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="Cnfirmation_import_users"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle id="alert-dialog-title">{"Change Password"}</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit} id="change_password_dialog">
            <Grid container spacing={2} id="change_password_container">
              {/**Password*/}
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="password"
                  type="password"
                  margin="dense"
                  label="Password"
                  variant="outlined"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              {/**Confirm Password*/}
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="confirm_pass"
                  type="password"
                  margin="dense"
                  label="Confirm Password"
                  variant="outlined"
                  value={formik.values.confirm_pass}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.confirm_pass &&
                    Boolean(formik.errors.confirm_pass)
                  }
                  helperText={
                    formik.touched.confirm_pass && formik.errors.confirm_pass
                  }
                />
              </Grid>
            </Grid>
            {/**Buttons container*/}
            <Grid
              container
              spacing={2}
              style={{ marginTop: "10px" }}
              className={classes.ButtonsContainers}
            >
              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={3} md={3}>
                <Button
                  onClick={Cancel}
                  variant="contained"
                  fullWidth
                  className={classes.Button}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={3} md={3}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={saving}
                  className={classes.Button}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      {/* Show success or error */}
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleCloseSnackbar}
      />
    </div>
  );
}

PasswordDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  user: PropTypes.object,
  getUser: PropTypes.func,
  currentRole: PropTypes.number,
  userPrefix: PropTypes.string,
  newRole: PropTypes.number,
};
