import React, { useState, useEffect, Fragment } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Grid,
  Typography,
  Button,
  Hidden,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  LinearProgress,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import {
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import io from "socket.io-client";

// Custom components
import UserDialog from "../components/users/UserDialog";
import SnackbarMessage from "../components/SnackbarMessage";
import NavigationTabs from "../components/NavigationTabs";
import ImportUsersModal from "../components/users/ImportUsersModal";
import ModifyDivisionsUsersDialog from "../components/users/ModifyDivisionsUsersDialog";
import PointSchedulerDialog from "../components/PointSchedulerDialog";

// API
import { getDivisionCategories } from "../api/Clients";
import { listRoles } from "../api/Roles";
import {
  createUser,
  updateUser,
  deleteUser,
  ModifyUsersByIds,
  createExportUsersQueue,
  getAllUsersForTableView,
  getEmailQueue,
  markAsViewed,
} from "../api/Users";
import { getUserPoints, createPoints } from "../api/Points";
import { listApprovalGroups } from "../api/ApprovalGroups";

// Styles needed for responsiveness
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "190px",
    },
  },
  modifyopenbutton: {
    [theme.breakpoints.up("md")]: {
      //float: "right",
      marginLeft: "10px",
    },
  },
  SelectButton: {
    float: "right",
  },
  SelectButtonCancel: {
    [theme.breakpoints.up("md")]: {
      float: "right",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "10px",
    },
  },
}));

// Custom Linear Progress with label
function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

// We need use forwardRef in order to expose ref to getUsers for data refreshing
const UserList = (props) => {
  const classes = useStyles();
  //check if url contains client_id
  let { client_id } = useParams();
  const [division_categories, setDivisionCategories] = useState([]);
  const [approval_groups, setApprovalGroups] = useState([]);
  const [user_division_categories, setUserDivisionCategories] = useState([]);
  const [roles, setRoles] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [userList, setUserList] = useState([]);
  const [fromClient] = React.useState(client_id ? true : false);
  const [OpenUserimport, setUserimport] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [isCustomer, setCustomer] = useState(false);
  const history = useHistory();
  const [exportUserOpen, setExportUserOpen] = useState(false);
  const [Select, setSelect] = useState(false);
  const [openModifyDialog, setOpenModifyDialog] = useState(false);
  const [ModifyDivisions, setModifyDivisions] = useState(false);
  const [loadingDivisions, setloadingDivisions] = useState(true);
  const [clientUsers, setClentUsers] = useState([]);
  const currentClient = props.clients.find((client) => client.id == client_id);
  const [usersToModify, setUsersToModify] = useState([]);
  const [selectedUsersIndex, setSelectedUsersindex] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [columnViewPorcentage, setColumViewPorcentage] = useState(100 / 6);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // Steps for email queue
  const emailQueueSteps = [
    "Request received", // null
    "Request in process queue", // queued
    "Processing request", // processing
    "Done. Check your email", // done
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [emailQueue, setEmailQueue] = useState(null);
  const [progress, setProgress] = React.useState(0);

  // Initial form data
  const initial_user = {
    id: null,
    first_name: "",
    last_name: "",
    username: "",
    password: "",
    email: "",
    billing_department: "",
    site: "",
    confirmed: false,
    division_categories: [],
    role: "",
    group_ids: 0,
    hireDate: null,
    Employee_ID: "",
  };

  // Open user dialog
  const handleClickOpen = (user) => {
    setEdit(false);
    //set user points
    let divisionCategories = [];
    division_categories.forEach((category) => {
      let currentCategory = {
        id: category.id,
        name: category.Name,
        balance: 0,
        original_balance: 0,
        checked: false,
        transaction_amount: 0,
      };
      divisionCategories.push(currentCategory);
    });
    if (!user) {
      setUserDivisionCategories([]);
      resetUserForm(initial_user, divisionCategories, user_division_categories);
      setOpen(true);
    } else if (user) {
      // We are editing user
      setEdit(true);

      // Set user form values
      let open_user_division_categories = user.division_categories.map((dc) => {
        return dc.id;
      });
      setUserDivisionCategories(open_user_division_categories);

      if (user_division_categories.length == 0) {
        resetUserForm(user, divisionCategories, user_division_categories);
        setOpen(true);
      } else {
        //get user balance for current division categories
        getUserPoints(user.id).then((res) => {
          if (res.data.userPoints && res.data.userPoints.length) {
            res.data.userPoints.forEach((division) => {
              //check the array of client divisions
              for (let index = 0; index < divisionCategories.length; index++) {
                if (
                  division.division_category_id == divisionCategories[index].id
                )
                  divisionCategories[index] = {
                    id: division.division_category_id,
                    balance:
                      division.points_balance != null
                        ? division.points_balance
                        : 0,
                    original_balance:
                      division.points_balance != null
                        ? division.points_balance
                        : 0,
                    transaction_amount: 0,
                    checked: false,
                    name: division.DivisionName,
                  };
              }
            });
          }
          resetUserForm(user, divisionCategories, user_division_categories);
          setOpen(true);
        });
      }
    }
  };

  const resetUserForm = (
    user,
    divisionCategories,
    user_division_categories
  ) => {
    formik.resetForm({
      values: {
        id: user.id,
        first_name: user.Details
          ? user.Details.FirstName
            ? user.Details.FirstName
            : ""
          : "",
        last_name: user.Details
          ? user.Details.LastName
            ? user.Details.LastName
            : ""
          : "",
        username: user.username,
        password: "",
        email: user.email,
        billing_department: user.Details
          ? user.Details.BillingDepartment
            ? user.Details.BillingDepartment
            : ""
          : "",
        site: user.Details ? (user.Details.Site ? user.Details.Site : "") : "",
        confirmed: user.confirmed,
        division_categories: divisionCategories,
        user_division_categories: user_division_categories,
        role: user.role.id,
        group_ids: user.group_ids,
        hireDate: user.hireDate || null,
        Employee_ID: user.Employee_ID || "",
      },
    });
  };

  //Open Import user modal
  const ShowimporUser = () => {
    setUserimport(true);
  };

  // Close user dialog
  const handleClose = () => {
    setCustomer(false);
    setOpen(false);
    setUserimport(false);
    setOpenModifyDialog(false);
    setModifyDivisions(false);
    formik.resetForm({
      values: initial_user,
    });
  };

  // Manage errors
  const [error, setError] = useState({
    message: "",
    severity: "info",
    open: false,
  });

  // Close snackbar
  const handleCloseSnackbar = () => {
    setError({
      open: false,
      message: "",
      severity: "info",
    });
  };

  // Get user entries
  const getUsers = () => {
    setLoading(true);
    const filterClientId = fromClient && client_id ? client_id : null;
    const filterResellerId = filterClientId
      ? null
      : localStorage.getItem("reseller_id");
    // Get all users for table view
    getAllUsersForTableView(filterClientId, filterResellerId)
      .then((result) => {
        if (result && result.data && result.data.getUsersTable) {
          // Set user list
          const users = result.data.getUsersTable;
          setUserList(users);
          setClentUsers(users);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false)); // set loading to false
  };

  // Delete single user
  const removeUser = (id) => {
    setSaving(true);
    deleteUser(id)
      .then((result) => {
        setSaving(false);
        if (result.errors) {
          setError({
            open: true,
            message: `Error: ${result.errors[0].message}`,
            severity: "error",
          });
          localStorage.removeItem("userViewTable");
          localStorage.removeItem("currentUserPage");
          localStorage.removeItem("currentUserRows");
        } else {
          getUsers();
          handleClose();
          setError({
            open: true,
            message: "User Deleted Successfully",
            severity: "success",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // List users at the first time and when client_id changes
  useEffect(() => {
    // Get users
    getUsers();
    setUsersToModify([]);
    // Get email queue status
    if (client_id) getEmailQueueStatus();
  }, [client_id]);

  // List division categories, approval groups & roles at the first load
  useEffect(() => {
    // List division categories
    if (client_id && client_id != 0) {
      // List division categories
      getDivisionCategories({ client_id })
        .then((result) => {
          setDivisionCategories(result);
          setloadingDivisions(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // List approval groups
    listApprovalGroups(client_id)
      .then((result) => {
        if (result && result.data && result.data.approvalGroups) {
          setApprovalGroups(result.data.approvalGroups);
          // setloadingDivisions(false);
        } else {
          console.log("Error listing approval groups", error);
        }
      })
      .catch((error) => {
        console.log("Error listing approval groups", error);
      });
    // List roles
    listRoles()
      .then((result) => {
        let role = localStorage.getItem("role");
        let roles = result.data.roles;
        let filter_roles = [];
        switch (role) {
          case "clientusermanager":
            for (let i = 0; i < roles.length; i++) {
              if (
                (roles[i] && roles[i].id == 3) ||
                roles[i].id == 4 ||
                roles[i].id == 7
              ) {
                filter_roles.push(roles[i]);
              }
            }
            setRoles(filter_roles);
            break;
          case "superadmin":
            if (fromClient) {
              for (let i = 0; i < roles.length; i++) {
                if (roles[i].id != 8 && roles[i].id != 6) {
                  filter_roles.push(roles[i]);
                }
              }
              setRoles(filter_roles);
            } else {
              setRoles(result.data.roles);
            }
            break;
          case "clientorderapprover":
            for (let k = 0; k < roles.length; k++) {
              if (
                (roles[k] && roles[k].id == 4) ||
                roles[k].id == 7 ||
                roles[k].id == 5
              ) {
                filter_roles.push(roles[k]);
              }
            }
            break;
          case "reselleradmin":
            for (let i = 0; i < roles.length; i++) {
              if (fromClient) {
                if (roles[i] && roles[i].id != 6 && roles[i].id != 8) {
                  filter_roles.push(roles[i]);
                }
              } else {
                if (roles[i] && roles[i].id != 6) {
                  filter_roles.push(roles[i]);
                }
              }
            }
            setRoles(filter_roles);
            break;
          case "clientadmin":
            for (let i = 0; i < roles.length; i++) {
              if (
                (roles[i] && roles[i].id == 9) ||
                roles[i].id == 3 ||
                roles[i].id == 4 ||
                roles[i].id == 5 ||
                roles[i].id == 7
              ) {
                filter_roles.push(roles[i]);
              }
            }
            setRoles(filter_roles);
            break;
          case "clientproductmanager":
            for (let i = 0; i < roles.length; i++) {
              if (
                (roles[i] && roles[i].id == 5) ||
                roles[i].id == 7 ||
                roles[i].id == 3 ||
                roles[i].id == 4
              ) {
                filter_roles.push(roles[i]);
              }
            }
            setRoles(filter_roles);
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  /**
   * Formik validation schema
   */
  const validationSchema = yup.object({
    first_name: yup.string("First Name").required("First name is required"),
    last_name: yup.string("Last Name").required("Last name is required"),
    username: yup.string("Username").required("Username is required"),
    password:
      edit || isCustomer
        ? yup
            .string("Password")
            .min(8, "Password must contain at least 8 characteres")
        : yup
            .string("Password")
            .required("Password is required")
            .min(8, "Password must contain at least 8 characteres"),
    email: yup.string("Email").required("Email is required"),
    billing_department: yup.string("Billing Department"),
    site: yup.string("Site"),
    confirmed: yup.bool(),
    division_categories: yup
      .array()
      .typeError("Division Categories must be an array"),
    role: edit
      ? yup.number().typeError("Role must be a number")
      : yup
          .number()
          .required("Role is required")
          .typeError("Role must be an number"),
    group_ids: yup.number().typeError("Approval Groups must be a number"),
    hireDate: yup.date("Hire Date").nullable(),
    Employee_ID: yup.string("Employee ID").required("Employee ID is required"),
  });

  // Manage form data
  const formik = useFormik({
    initialValues: initial_user,
    validationSchema: validationSchema,
    onSubmit: (original_values) => {
      let values = { ...original_values };
      setSaving(true);
      //check if the user has the client's division categories.
      values.user_division_categories = user_division_categories;
      // Removing spaces from the user
      const properties = ["first_name", "last_name", "username"];
      for (const property of properties) {
        if (`${property}` in values) {
          values[property] = values[property].trim();
        }
      }
      //Customer
      if (isCustomer && values.role == 7) {
        // If no pass provided, set default one
        if (values.password === "") {
          values.password = "uHFLoLmlcqg1&y!KhbnQW9LIoI*a9w3O";
        }
        //If not a test user.
        if (fromClient) {
          //get the client domain and add it automatically to the username
          values.username = currentClient.subdomain + "-" + values.username;
        }

        if (client_id && client_id != 0) {
          values.clients = [client_id];
        }
      }
      // Relate to client if it is selected
      if (client_id && client_id != 0) {
        values.clients = [client_id];
      }
      // Validate hire date
      if (values.hireDate) {
        values.hireDate = moment(values.hireDate).format("YYYY-MM-DD");
      }
      (values.id == null ? createUser(values, fromClient) : updateUser(values))
        .then((res) => {
          let resp_prop_name = values.id == null ? "createUser" : "updateUser";
          if (
            "data" in res &&
            resp_prop_name in res.data &&
            res.data[resp_prop_name] &&
            res.data[resp_prop_name].user
          ) {
            localStorage.removeItem("userViewTable");
            localStorage.removeItem("currentUserPage");
            localStorage.removeItem("currentUserRows");
            //save points changes
            //Check every category to see if there are changes in points, then create a new record for each one
            values.division_categories.forEach((category) => {
              //but first, check if the user divison categories array contains category
              if (user_division_categories.includes(category.id)) {
                if (category.balance != category.original_balance) {
                  let message = `Added ${
                    category.balance - category.original_balance
                  } points`;
                  category.message = message;
                  createPoints(
                    values.id ? values.id : res.data.createUser.user.id,
                    category
                  ).then((res) => {
                    console.log(res);
                  });
                }
              }
            });
            getUsers();
            handleClose();
            setError({
              open: true,
              message:
                "User " +
                (values.id == null ? "Created" : "Updated") +
                " Successfully",
              severity: "success",
            });
            formik.resetForm();
          } else {
            // Display error
            let error_message =
              "errors" in res &&
              Array.isArray(res.errors) &&
              res.errors.length > 0 &&
              "message" in res.errors[0]
                ? res.errors[0].message
                : `An error occurred, please try again`;
            setError({
              open: true,
              message: error_message,
              severity: "error",
            });
          }
        })
        .catch((res) => {
          // Display error
          let error_message =
            "errors" in res &&
            Array.isArray(res.errors) &&
            res.errors.length > 0 &&
            "message" in res.errors[0]
              ? res.errors[0].message
              : `An error occurred, please try again`;
          setError({
            open: true,
            message: error_message,
            severity: "error",
          });
        })
        .then(function () {
          setSaving(false);
        });
    },
  });

  const addDivisionCategory = (value) => {
    let open_user_division_categories = [...user_division_categories];
    let flag = false;
    for (let i = 0; i < open_user_division_categories.length; i++) {
      if (open_user_division_categories[i] == value.id) {
        flag = true;
      }
    }
    if (!flag) {
      open_user_division_categories.push(value.id);
    }
    setUserDivisionCategories(open_user_division_categories);
  };

  const removeDivisionCategory = (value) => {
    let open_user_division_categories = [...user_division_categories];
    let index = false;
    for (let i = 0; i < open_user_division_categories.length; i++) {
      if (open_user_division_categories[i] == value.id) {
        index = i;
      }
    }
    open_user_division_categories.splice(index, 1);
    value.balance = 0;
    setUserDivisionCategories(open_user_division_categories);
  };

  /**
   * Create export users queue
   * @param {Int} client_id 'ID' Field in the client's content type
   */
  const createUserExportQueue = async (client_id) => {
    // Validate client id
    if (client_id && client_id != 0) {
      // Create export users queue
      createExportUsersQueue(client_id, filterUsers)
        .then(async (result) => {
          // Get email queue id
          const emailQueueId =
            result?.data?.createEmailQueue?.emailQueue?.id || null;
          // Validate email queue id
          if (emailQueueId) {
            // Set success message
            setError({
              open: true,
              message: `Users export queue Created Successfully`,
              severity: "success",
            });
            // Get email queue status
            getEmailQueueStatus();
          } else {
            setError({
              open: true,
              message: `An error occurred, please try again`,
              severity: "error",
            });
          }
        })
        .catch(() => {})
        .finally(() => setExportUserOpen(false));
    }
  };

  // Open Export users dialog
  const handleClickOpenExportUserModal = () => {
    const value = exportUserOpen ? false : true;
    setExportUserOpen(value);
  };

  // Export users modal/dialog :/
  const ExportUsersModal = (props) => {
    const { open, handleClose /*, UserToExport , getAllUsers*/ } = props;
    return (
      <div>
        <Dialog
          id="usersexport_dialog"
          open={open}
          onClose={handleClose}
          aria-labelledby="Cnfirmation_import_users"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={"sm"}
        >
          <DialogTitle id="alert-dialog-title">{"Export Users"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid>
                <Typography gutterBottom>
                  All client users will be exported to a CSV file and sent by
                  email later. Do you want to continue?
                </Typography>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid item xs={5} md={6} lg={7} />
            <Grid item xs={7} md={6} lg={5}>
              <Grid container spacing={2}>
                <Grid item xs={5} sm={6} md={5} lg={5}>
                  <Button onClick={handleClose} variant="contained" fullWidth>
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={7} sm={6} md={7} lg={7}>
                  <Button
                    type="submit"
                    onClick={() => {
                      createUserExportQueue(client_id);
                    }}
                    color="primary"
                    autoFocus
                    variant="contained"
                    fullWidth
                  >
                    Continue
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  // propTypes
  ExportUsersModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    client_id: PropTypes.string,
  };

  const DisableUsers = () => {
    let users = usersToModify;
    if (users.length > 0) {
      let value = { confirmed: false, users: users, action: "disable" };
      ModifyUsersByIds(value)
        .then((result) => {
          if (result.length > 0) {
            setUsersToModify([]);
            getUsers();
            setError({
              open: true,
              message: "Users Disabled Successfully",
              severity: "success",
            });
          } else {
            setError({
              open: true,
              message: `An error occurred, please try again`,
              severity: "error",
            });
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        })
        .finally(() => {
          setSelect(false);
          setUsersToModify([]);
        });
    }
  };

  //Modufy user Dialog to small screens
  const ModufyDialog = (props) => {
    const { open, handleClose, usersToModify = [], DisableUsers } = props;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Disable multiple users"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {usersToModify.length > 0
              ? "Multple users will be disabled. Do you want to continue?"
              : "You have not selected any user to disable, select some to continue"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="default">
            CANCEL
          </Button>
          <Button
            onClick={() => {
              DisableUsers();
              handleClose();
            }}
            variant="contained"
            color="secondary"
            autoFocus
            disabled={usersToModify.length > 0 ? false : true}
          >
            DISABLE USERS
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  ModufyDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    usersToModify: PropTypes.array,
    DisableUsers: PropTypes.func,
  };

  // Open alert dialog - users disable
  const HandleOpenModifyDialog = () => {
    setOpenModifyDialog(true);
  };

  // Open modify division users
  const handleModifyUsers = () => {
    setModifyDivisions(true);
  };

  // Custom styles for text column
  const customStyle = ({ ...column }) => {
    return (
      <div
        className={classes.ListItem}
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          fontWeight: "bold",
          padding: "10px",
          height: "10px",
          width: "100%",
        }}
      >
        {column.label}
      </div>
    );
  };
  // Custom style by columns
  const customStyleText = (value = "") => {
    // Validate user id
    let userId = null;
    if (value && value.startsWith('{"id":')) {
      const user = JSON.parse(value);
      // Get user name
      value = user.username || "-";
      userId = user.id || null;
    }
    value = value && value.length !== 0 ? value : "-";
    // Adding user id
    const key = userId ? { key: userId } : {};
    return (
      <div
        {...key}
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {value}
      </div>
    );
  };

  // Websocket connection
  useEffect(() => {
    // Connect to the websocket
    const socket = io(process.env.REACT_APP_REST_URL, {
      query: { token: localStorage.getItem("token") },
      secure: true,
    });
    // Listen for status updates
    socket.on("statusUpdate", (data) => {
      // Validate email queue
      if (
        data.client_id == client_id &&
        data.user_id == localStorage.getItem("user")
      ) {
        // Get email queue status
        setTimeout(() => getEmailQueueStatus(), 1000);
      }
    });
    // Clean up: disconnect the socket when the component unmounts
    return () => {
      socket.disconnect();
      // Get email queue status
      const status = emailQueue ? emailQueue.status : null;
      // Mark as viewed
      if (status == "DONE") {
        markAsViewed(emailQueue.id);
      }
    };
  }, [emailQueue]);

  // Get email queue status
  const getEmailQueueStatus = () => {
    // Get email queue
    getEmailQueue(client_id).then((res) => {
      // Get email queue
      const emailQueue = res?.data?.emailQueues?.[0] || null;
      // Set email queue
      setEmailQueue(emailQueue);
      // Validate email queue
      if (emailQueue) {
        // Get email queue status
        const status = emailQueue.status;
        // Set active step
        switch (status) {
          case "QUEUED":
            setActiveStep(1);
            setProgress(50);
            break;
          case "PROCESSING":
            setActiveStep(2);
            setProgress(75);
            break;
          case "DONE":
            setActiveStep(3);
            setProgress(100);
            break;
          default:
            setActiveStep(0);
            setProgress(25);
            break;
        }
      }
    });
  };

  // Mui Data table custom Stykes
  const theme = () =>
    createMuiTheme({
      overrides: {
        //Table head custom styles
        MUIDataTableHeadCell: {
          fixedHeader: {
            paddingTop: "0px",
            paddingBottom: "10px",
            backgroundColor: "#F2F2F2",
            borderRight: "1px solid #E5E3E3",
          },
        },
        // Search form custom style
        MUIDataTableSearch: {
          main: {
            visibility: true,
          },
        },
        // Chip filters custom styles
        MUIDataTableFilterList: {
          root: {
            marginBottom: "10px",
          },
        },
        // Filter container custom styles
        MuiGrid: {
          container: {
            display: "block",
            width: "210px",
            paddingLeft: "opx",
          },
          item: {
            boxSizing: "content-box",
          },
        },
        // Filter list options custom styles
        MUIDataTableFilter: {
          checkboxIcon: {
            padding: "0px",
          },
          checkboxFormControlLabel: {
            padding: "0px",
          },
          checkboxListTitle: {
            width: "100%",
          },
        },
        MUIDataTableSelectCell: {
          headerCell: {
            backgroundColor: "#F2F2F2",
          },
        },
        MUIDataTableToolbarSelect: {
          root: { visibility: "hidden", minHeight: "49px" },
        },
        MuiTableHead: {
          root: {
            display: `${columnViewPorcentage ? "table-header-group" : "none"}`,
          },
        },
        MuiTableBody: {
          root: {
            display: `${columnViewPorcentage ? "table-row-group" : "none"}`,
          },
        },
      },
    });

  const initialColums = [
    // Name column
    {
      name: "Name",
      label: "Name",
      options: {
        filter: false,
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleText(value),
      },
    },
    // Username column
    {
      name: "Control_column",
      label: "Username",
      options: {
        filter: false,
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleText(value),
      },
    },
    // Billing department column
    {
      name: "Billing_Department",
      label: "Billing Department",
      options: {
        filter: true,
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleText(value),
      },
    },
    // Role column
    {
      name: "Role",
      label: "Role",
      options: {
        filter: true,
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleText(value),
      },
    },
    // Status column
    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleText(value),
      },
    },
    // Hire Date Column
    {
      name: "Hire_date",
      label: "Date of Hire",
      options: {
        filter: false,
        setCellProps: () => {
          return {
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
          };
        },
        customHeadLabelRender: customStyle,
        customBodyRender: (value) => customStyleText(value),
      },
    },
  ];

  // Table options
  const options = {
    filter: true,
    filterType: "checkbox",
    sortFilterList: true,
    selectableRows: !fromClient ? false : "multiple",
    searchPlaceholder: "Search all users",
    textLabels: {
      body: {
        noMatch: "Sorry, no matching records found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `${column.label}`,
      },
    },
    download: false,
    responsive: "standard",
    onRowClick: (rowData) => {
      // Get user id
      const userId = rowData[1].key;
      // Redirect to user client view
      fromClient && userId
        ? history.push(`/app/client/${client_id}/user/${userId}`)
        : userId && history.push(`/app/user/${userId}`);
    },
    elevation: 0,
    onRowSelectionChange(currentRowsSelected, allRowsSelected, rowsSelected) {
      // Gets the selected users in the table
      if (
        rowsSelected &&
        Array.isArray(rowsSelected) &&
        rowsSelected.length != 0
      ) {
        let users = [];
        for (const index of rowsSelected) {
          const user = clientUsers[index];
          if (user.Status == "Active") users.push(user);
        }
        setUsersToModify(users);
        if (users.length !== 0) setSelect(true);
      } else {
        setSelect(false);
        setUsersToModify([]);
      }
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      // Validates the selected users
      let selectedIds = [];
      let allUsersIndex = [];
      for (const userIndex of selectedRows.data) {
        const index =
          userIndex && userIndex.index >= 0 ? userIndex.index : null;
        if (index >= 0) {
          const username = displayData[index].data[1].key;
          selectedIds.push(username);
          const user = clientUsers.find((user) => user.id == username);
          if (user && typeof user == "object" && user.Status == "Active") {
            allUsersIndex.push(index);
          }
        }
      }
      // Check if you meet the condition to be selected
      const canSelect = selectedIds.every(
        (username) =>
          clientUsers.find((item) => item.id === username)?.Status === "Active"
      );
      if (canSelect && allUsersIndex.length !== selectedUsersIndex.length) {
        setSelectedUsersindex(allUsersIndex);
        setSelectedRows(allUsersIndex);
      } else if (!canSelect) {
        setSelectedRows(allUsersIndex);
      }
    },
    onTableChange: (action, state) => {
      // Fitering by columns
      if (action == "filterChange") {
        const usersData =
          state && state.displayData && state.displayData.length > 0
            ? state.displayData
            : [];
        let filterUsers = [];
        for (const userData of usersData) {
          const username = userData.data[1].key;
          const user = clientUsers.find((user) => user.id == username);
          if (user && typeof user == "object") {
            filterUsers.push(user.id);
          }
        }
        clientUsers.length !== filterUsers.length
          ? setFilterUsers(filterUsers)
          : setFilterUsers([]);
      }
      // columns porcetage views
      if (action == "viewColumnsChange") {
        const columns = state.columns;
        let countColumns = columns.length;
        let showColumns = 0;
        for (const column of columns) {
          if (column.display == "true") {
            showColumns++;
          }
        }
        if (showColumns !== columns.length) {
          countColumns = showColumns;
        }
        const columnViewPorcentage =
          countColumns > 0 ? 100 / countColumns : null;
        setColumViewPorcentage(columnViewPorcentage);
      }
    },
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 50, 100],
    page: page,
    onChangePage: (currentPage) => {
      localStorage.setItem("currentUserPage", currentPage);
      setPage(currentPage);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      localStorage.setItem("currentUserRows", numberOfRows);
      setRowsPerPage(numberOfRows);
      setPage(0);
    },
  };

  return (
    // Container
    <Container
      id="main_container"
      maxWidth="xl"
      style={{ marginTop: "65px" }}
      className={classes.mainContainer}
    >
      {/* Content */}
      <Grid container spacing={3} style={{ padding: "20px" }} id="second_grid">
        {fromClient && client_id != 0 ? (
          // Navigation tabs
          <Grid item xs={12} md={10} id="grid_tabs">
            <NavigationTabs client_id={Number(client_id)} value={"users"} />
          </Grid>
        ) : (
          // Title
          <Grid item xs={12} id="grid_title">
            <Typography variant="h4" gutterBottom>
              Users
            </Typography>
          </Grid>
        )}
        {/* Buttons add - import - export */}
        <Grid item xs={12} sm={12} md={12} lg={12} id="grid_button_add">
          {/* Hidden button on sm and upper screens */}
          <Hidden smUp>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleClickOpen(null)}
              disabled={client_id == 0 ? true : false}
            >
              + Add User
            </Button>
          </Hidden>
          {/* Hidden button on xs and lower screens */}
          <Hidden xsDown>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClickOpen(null)}
              disabled={client_id == 0 ? true : false}
            >
              + Add User
            </Button>
          </Hidden>
          {/* Import button */}
          {fromClient && client_id != 0 && (
            <React.Fragment>
              {/* Hidden button on sm and upper screens */}
              <Hidden smUp>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => ShowimporUser()}
                  style={{ marginTop: "10px" }}
                >
                  Import
                </Button>
              </Hidden>
              {/* Hidden button on xs and lower screens */}
              <Hidden xsDown>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => ShowimporUser()}
                  style={{ marginLeft: "10px" }}
                >
                  Import
                </Button>
              </Hidden>
            </React.Fragment>
          )}
          {/* Export button */}
          {fromClient && client_id != 0 && (
            <React.Fragment>
              {/* Hidden button on sm and upper screens */}
              <Hidden smUp>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => handleClickOpenExportUserModal()}
                  style={{ marginTop: "10px" }}
                  disabled={emailQueue && emailQueue?.status !== "DONE"}
                >
                  Export
                </Button>
              </Hidden>
              {/* Hidden button on xs and lower screens */}
              <Hidden xsDown>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleClickOpenExportUserModal()}
                  style={{ marginLeft: "10px" }}
                  disabled={emailQueue && emailQueue?.status !== "DONE"}
                >
                  Export
                </Button>
              </Hidden>
            </React.Fragment>
          )}
          {/**Buttons Modify users*/}
          {Select && fromClient && client_id != 0 && (
            <Fragment>
              {/**Change division categories buttons */}
              <Hidden smUp>
                <Button
                  fullWidth
                  color="primary"
                  onClick={() => {
                    handleModifyUsers();
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  CHANGE DIVISION
                </Button>
              </Hidden>
              <Hidden xsDown>
                <Button
                  color="primary"
                  onClick={() => {
                    handleModifyUsers();
                  }}
                  className={classes.modifyopenbutton}
                >
                  CHANGE DIVISION
                </Button>
              </Hidden>
              <Hidden smUp>
                <PointSchedulerDialog
                  openButtonText="MANAGE POINTS"
                  openButtonVariant="text"
                  openButtonColor="primary"
                  openButtonFullWidth={true}
                  dialogTitle="Manage Points"
                  division_categories={[]}
                  setError={setError}
                  getSchedulePoints={() => {}}
                  client_id={client_id}
                  userIds={[
                    ...usersToModify.map((user) => {
                      return user.id;
                    }),
                  ]}
                />
              </Hidden>
              <Hidden xsDown>
                <PointSchedulerDialog
                  openButtonText="MANAGE POINTS"
                  openButtonVariant="text"
                  openButtonColor="primary"
                  openButtonFullWidth={false}
                  dialogTitle="Manage Points"
                  division_categories={division_categories}
                  setError={setError}
                  getSchedulePoints={() => {}}
                  client_id={client_id}
                  userIds={[
                    ...usersToModify.map((user) => {
                      return user.id;
                    }),
                  ]}
                />
              </Hidden>
              {/**Disable buttons */}
              <Hidden smUp>
                <Button
                  fullWidth
                  color="secondary"
                  onClick={HandleOpenModifyDialog}
                  style={{ marginLeft: "10px" }}
                >
                  DISABLE
                </Button>
              </Hidden>
              {/* Hidden button on xs and lower screens */}
              <Hidden xsDown>
                <Button
                  color="secondary"
                  onClick={HandleOpenModifyDialog}
                  style={{ marginLeft: "10px" }}
                >
                  DISABLE
                </Button>
              </Hidden>
            </Fragment>
          )}
          {/**Export users status */}
          {emailQueue && (
            <>
              <Hidden smUp>
                <span style={{ float: "right", width: "100%" }}>
                  <Typography>{`Export status: ${emailQueueSteps[activeStep]}`}</Typography>
                  <LinearProgressWithLabel value={progress} />
                </span>
              </Hidden>
              <Hidden xsDown>
                <span style={{ float: "right", width: "295px" }}>
                  <Typography>{`Export status: ${emailQueueSteps[activeStep]}`}</Typography>
                  <LinearProgressWithLabel value={progress} />
                </span>
              </Hidden>
            </>
          )}
        </Grid>
        {/**Table Skeletor loading */}
        {loading ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={9} style={{ padding: "12px" }}>
                  <Skeleton
                    variant="rectangular"
                    height={30}
                    style={{ margin: "3px" }}
                    width={120}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    padding: "12px 25px 12px 12px",
                    marginBottom: "20px",
                  }}
                >
                  {[1, 2, 3, 4].map((row, index) => {
                    return (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        height={30}
                        style={{ margin: "3px", float: "right" }}
                        width={40}
                      />
                    );
                  })}
                </Grid>
              </Grid>
              <Grid container>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((row, i) => {
                  return (
                    <Grid key={i} container>
                      <Grid item xs={2}>
                        <Skeleton
                          variant="rectangular"
                          height={30}
                          style={{ margin: "3px", marginBottom: "15px" }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Skeleton
                          variant="rectangular"
                          height={30}
                          style={{ margin: "3px" }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Skeleton
                          variant="rectangular"
                          height={30}
                          style={{ margin: "3px" }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Skeleton
                          variant="rectangular"
                          height={30}
                          style={{ margin: "3px" }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Skeleton
                          variant="rectangular"
                          height={30}
                          style={{ margin: "3px" }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        ) : userList.length < 1 || clientUsers.length < 1 ? (
          <Grid item xs={12}>
            {client_id == 0 ? (
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  This user doesn&apos;t have any client, please contact an
                  administrator.
                </Typography>
              </Grid>
            ) : (
              <Typography variant={"h5"}>
                No users for this {fromClient ? " client" : " reseller"}
              </Typography>
            )}
          </Grid>
        ) : (
          <Grid xs={12} sm={12} md={12} lg={12}>
            {/**Client Users Table List */}
            <MuiThemeProvider theme={theme}>
              <MUIDataTable
                data={clientUsers}
                columns={initialColums}
                options={options}
              />
            </MuiThemeProvider>
          </Grid>
        )}
      </Grid>
      {/* Create and update user dialog */}
      <UserDialog
        open={open}
        handleClose={handleClose}
        division_categories={division_categories}
        approval_groups={approval_groups}
        roles={roles}
        formik={formik}
        removeUser={removeUser}
        user_division_categories={user_division_categories}
        addDivisionCategory={addDivisionCategory}
        removeDivisionCategory={removeDivisionCategory}
        fromClient={fromClient}
        saving={saving}
        setCustomer={setCustomer}
        currentClient={currentClient}
      />
      {/* Show success or error */}
      <SnackbarMessage
        open={error.open}
        severity={error.severity}
        message={error.message}
        handleClose={handleCloseSnackbar}
      />
      {/*Show Import User Modal */}
      <ImportUsersModal
        open={OpenUserimport}
        handleClose={handleClose}
        getUsers={getUsers}
        setError={setError}
      />
      {/**Show ModifyDivisionsUsersDialog Modal */}
      <ModifyDivisionsUsersDialog
        open={ModifyDivisions}
        handleClose={handleClose}
        usersToModify={usersToModify}
        divisionCategories={division_categories}
        loading={loadingDivisions}
      />
      {/** Show Alert dialog export users */}
      {exportUserOpen && (
        <ExportUsersModal
          open={exportUserOpen}
          handleClose={handleClickOpenExportUserModal}
          client_id={client_id}
        />
      )}
      {/** Open modifu dialog to small screens*/}
      {openModifyDialog && (
        <ModufyDialog
          open={openModifyDialog}
          handleClose={handleClose}
          usersToModify={usersToModify}
          DisableUsers={DisableUsers}
        />
      )}
    </Container>
  );
};

UserList.propTypes = {
  clients: PropTypes.array,
};

export default UserList;
