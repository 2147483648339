import axios from "axios";

const axiosOptions = {
  url: process.env.REACT_APP_GRAPHQL_URL,
  method: "post",
};

/**
 * DEPRECATED - Use listReports instead
 * @param {*} client_id
 * @returns
 */
export const listReports = async (client_id) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
        query{
          usersDivisionsPoints(where: {clients: ${client_id}, _limit: -1})
            {
            id
            Details
            username
            confirmed
            division_categories{
              division_category_id
              DivisionName
              points_balance
            }
          }
          divisionCategories(where: {clients: ${client_id}, _limit: -1})
            {
            id
            Name
          }
        }
      `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Get list users points by client id
 * @param {*} clientId
 * @returns
 */
export const usersListReports = async (clientId) => {
  axiosOptions.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  axiosOptions.data = {
    query: `
      query {
        getUsersDivisionsPoints(client_id: ${clientId}) {
          id
          Username
          Name
          Status
          Billing_Department
          Employee_ID
          Division_Categories
        }
      }
    `,
  };
  try {
    const response = await axios(axiosOptions);
    return response.data;
  } catch (error) {
    return error;
  }
};
